<template>
    <div>
        <strong>Sensors and relays naming</strong>
        <hr>

        <b-row>
            <b-col cols="6" sm="5" md="5" lg="4" xl="3">
                <b-row class="pb-3">
                    <b-col cols="12">
                        <b-input-group size="md" prepend="S1">
                            <b-form-input class="input-sm" placeholder="Sensor 1" v-model="s1Name" :state="s1State" aria-describedby="s1-feedback"
                                          @input="(value) => {debounceUpdateSensorName(1, value)}"
                                          @click="$root.$emit('hideBottomNav')"></b-form-input>

                            <b-form-invalid-feedback id="s1-feedback">
                                {{ nameErrorMessage }}
                            </b-form-invalid-feedback>
                        </b-input-group>
                    </b-col>
                </b-row>

                <b-row class="pb-3">
                    <b-col cols="12">
                        <b-input-group size="md" prepend="S2">
                            <b-form-input class="input-sm" placeholder="Sensor 2" v-model="s2Name" :state="s2State" aria-describedby="s2-feedback"
                                          @input="(value) => {debounceUpdateSensorName(2, value)}"
                                          @click="$root.$emit('hideBottomNav')"></b-form-input>

                            <b-form-invalid-feedback id="s2-feedback">
                                {{ nameErrorMessage }}
                            </b-form-invalid-feedback>
                        </b-input-group>
                    </b-col>
                </b-row>

                <b-row class="pb-3">
                    <b-col cols="12">
                        <b-input-group size="md" prepend="S3">
                            <b-form-input class="input-sm" placeholder="Sensor 3" v-model="s3Name" :state="s3State" aria-describedby="s3-feedback"
                                          @input="(value) => {debounceUpdateSensorName(3, value)}"
                                          @click="$root.$emit('hideBottomNav')"></b-form-input>

                            <b-form-invalid-feedback id="s3-feedback">
                                {{ nameErrorMessage }}
                            </b-form-invalid-feedback>
                        </b-input-group>
                    </b-col>
                </b-row>

                <b-row class="pb-3">
                    <b-col cols="12">
                        <b-input-group size="md" prepend="S4">
                            <b-form-input class="input-sm" placeholder="Sensor 4" v-model="s4Name" :state="s4State" aria-describedby="s4-feedback"
                                          @input="(value) => {debounceUpdateSensorName(4, value)}"
                                          @click="$root.$emit('hideBottomNav')"></b-form-input>

                            <b-form-invalid-feedback id="s4-feedback">
                                {{ nameErrorMessage }}
                            </b-form-invalid-feedback>
                        </b-input-group>
                    </b-col>
                </b-row>
            </b-col>

            <b-col cols="6" sm="5" md="5" lg="4" xl="3">
                <b-row class="pb-3">
                    <b-col cols="12">
                        <b-input-group size="md" prepend="R1">
                            <b-form-input class="input-sm" placeholder="Relay 1" v-model="r1Name" :state="r1State" aria-describedby="r1-feedback"
                                          @input="(value) => {debounceUpdateRelayName(1, value)}" @click="$root.$emit('hideBottomNav')"></b-form-input>

                            <b-form-invalid-feedback id="r1-feedback">
                                {{ nameErrorMessage }}
                            </b-form-invalid-feedback>
                        </b-input-group>
                    </b-col>
                </b-row>

                <b-row class="pb-3">
                    <b-col cols="12">
                        <b-input-group size="md" prepend="R2">
                            <b-form-input class="input-sm" placeholder="Relay 2" v-model="r2Name" :state="r2State" aria-describedby="r2-feedback"
                                          @input="(value) => {debounceUpdateRelayName(2, value)}" @click="$root.$emit('hideBottomNav')"></b-form-input>

                            <b-form-invalid-feedback id="r2-feedback">
                                {{ nameErrorMessage }}
                            </b-form-invalid-feedback>
                        </b-input-group>
                    </b-col>
                </b-row>

                <b-row class="pb-3">
                    <b-col cols="12">
                        <b-input-group size="md" prepend="R3">
                            <b-form-input class="input-sm" placeholder="Relay 3" v-model="r3Name" :state="r3State" aria-describedby="r3-feedback"
                                          @input="(value) => {debounceUpdateRelayName(3, value)}" @click="$root.$emit('hideBottomNav')"></b-form-input>

                            <b-form-invalid-feedback id="r3-feedback">
                                {{ nameErrorMessage }}
                            </b-form-invalid-feedback>
                        </b-input-group>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import {debounce} from 'lodash';

    export default {
        name: "SensorsAndRelaysNaming",

        created() {
            this.debounceUpdateSensorName = debounce(this.updateSensorName, 500);
            this.debounceUpdateRelayName = debounce(this.updateRelayName, 500);
        },

        data() {
            return {
                deviceId: this.$route.params.id,
                minChars: 3,
                maxChars: 10
            }
        },

        computed: {
            ...mapGetters({
                getSensorName: 'virtualDevice/sensorName',
                getRelayName: 'virtualDevice/relayName'
            }),

            nameErrorMessage() {
                return `${this.$t('deviceEdit.sensorsAndRelaysNamingsPart1')} ${this.minChars} ${this.$t('deviceEdit.sensorsAndRelaysNamingsPart2')}
                    ${this.maxChars} ${ this.$t('deviceEdit.sensorsAndRelaysNamingsPart3') }`
            },

            s1State() {
                return this.s1Name.length >= this.minChars && this.s1Name.length <= this.maxChars;
            },

            s2State() {
                return this.s2Name.length >= this.minChars && this.s2Name.length <= this.maxChars;
            },

            s3State() {
                return this.s3Name.length >= this.minChars && this.s3Name.length <= this.maxChars;
            },

            s4State() {
                return this.s4Name.length >= this.minChars && this.s4Name.length <= this.maxChars;
            },

            r1State() {
                return this.r1Name.length >= this.minChars && this.r1Name.length <= this.maxChars;
            },

            r2State() {
                return this.r2Name.length >= this.minChars && this.r2Name.length <= this.maxChars;
            },

            r3State() {
                return this.r3Name.length >= this.minChars && this.r3Name.length <= this.maxChars;
            },

            s1Name: {
                get() {
                    return this.getSensorName({deviceId: this.deviceId, sensorId: 1});
                },

                set(name) {
                    this.$store.commit('virtualDevice/setSensorName', {
                        deviceId: this.deviceId,
                        sensorId: 1,
                        sensorName: name
                    });
                }
            },

            s2Name: {
                get() {
                    return this.getSensorName({deviceId: this.deviceId, sensorId: 2});
                },

                set(name) {
                    this.$store.commit('virtualDevice/setSensorName', {
                        deviceId: this.deviceId,
                        sensorId: 2,
                        sensorName: name
                    });
                }
            },

            s3Name: {
                get() {
                    return this.getSensorName({deviceId: this.deviceId, sensorId: 3});
                },

                set(name) {
                    this.$store.commit('virtualDevice/setSensorName', {
                        deviceId: this.deviceId,
                        sensorId: 3,
                        sensorName: name
                    });
                }
            },

            s4Name: {
                get() {
                    return this.getSensorName({deviceId: this.deviceId, sensorId: 4});
                },

                set(name) {
                    this.$store.commit('virtualDevice/setSensorName', {
                        deviceId: this.deviceId,
                        sensorId: 4,
                        sensorName: name
                    });
                }
            },

            r1Name: {
                get() {
                    return this.getRelayName({deviceId: this.deviceId, relayId: 1});
                },

                set(name) {
                    this.$store.commit('virtualDevice/setRelayName', {
                        deviceId: this.deviceId,
                        relayId: 1,
                        relayName: name
                    });
                }
            },

            r2Name: {
                get() {
                    return this.getRelayName({deviceId: this.deviceId, relayId: 2});
                },

                set(name) {
                    this.$store.commit('virtualDevice/setRelayName', {
                        deviceId: this.deviceId,
                        relayId: 2,
                        relayName: name
                    });
                }
            },

            r3Name: {
                get() {
                    return this.getRelayName({deviceId: this.deviceId, relayId: 3});
                },

                set(name) {
                    this.$store.commit('virtualDevice/setRelayName', {
                        deviceId: this.deviceId,
                        relayId: 3,
                        relayName: name
                    });
                }
            }
        },

        methods: {
            updateSensorName(sensorId, name) {
                let sensorName = this['s' + sensorId + "Name"];
                if (sensorName.length >= this.minChars && sensorName.length <= this.maxChars) {
                    let data = {
                        id: this.deviceId,
                        sensorId: sensorId,
                        name: name
                    };

                    this.$axios.post('/update-sensor-name', {
                        data: data
                    });
                }
            },

            updateRelayName(relayId, name) {
                let relayName = this['r' + relayId + "Name"];
                if (relayName.length >= this.minChars && relayName.length <= this.maxChars) {
                    let data = {
                        id: this.deviceId,
                        relayId: relayId,
                        name: name
                    };

                    this.$axios.post('/update-relay-name', {
                        data: data
                    });
                }
            }
        }
    }
</script>

<style scoped>
    .was-validated .form-control:valid, .form-control.is-valid, .was-validated .custom-select:valid, .custom-select.is-valid {
        border-color: #e0e0e0 !important;
    }
</style>