<template>
    <div>
        <h4 style="padding-bottom: 1.2em;"><i><em>{{ $t('deviceEdit.general') }}</em></i></h4>

        <b-row v-if="userPrivilege === 3">
            <b-col cols="5" sm="2">
                <label style="padding-top: 0.4em;">{{ $t('deviceEdit.deviceName') }}</label>
            </b-col>

            <b-col sm="8" md="8" lg="6" xl="4">
                <b-form-group id="fieldset1" label-for="deviceNameInput" :invalid-feedback="deviceNameInvalidFeedback"
                              :valid-feedback="deviceNameValidFeedback" f :state="deviceNameState">

                    <b-form-input type="text" autocomplete="off" class="form-control input-sm" id="deviceNameInput" v-model="deviceName"
                                  :state="deviceNameState" @click="$root.$emit('hideBottomNav')">
                    </b-form-input>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row v-if="isAdmin">
            <b-col cols="5" sm="2">
                <label style="padding-top: 0.4em;">{{ $t('deviceEdit.deviceDesc') }}</label>
            </b-col>

            <b-col sm="8" md="8" lg="6" xl="4">
                <b-form-group id="fieldset2" label-for="deviceNameInput" :invalid-feedback="deviceNameInvalidFeedback"
                              :valid-feedback="deviceNameValidFeedback" f :state="deviceNameState">

                    <b-form-input type="text" autocomplete="off" class="form-control input-sm" id="deviceNameInput" v-model="deviceDesc"
                                  :state="deviceNameState" @click="$root.$emit('hideBottomNav')">
                    </b-form-input>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row v-if="!isUnlinked">
            <b-col cols="12" sm="2">
                <label style="padding-top: 0.4em;">{{ $t('deviceEdit.firmwareVersion') }}</label>
            </b-col>

            <b-col sm="8" md="8" lg="6" xl="4">
                <b-form-group>
                    <b-form-input readonly type="text" v-model="firmwareVersion"/>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row class="mb-3">
            <b-col cols="12" sm="2">
                <label style="padding-top: 0.4em;">{{ $t('deviceEdit.muteNotifications') }}</label>
            </b-col>

            <b-col v-if="notificationsEnabled" sm="8" md="8" lg="6" xl="4">
                <b-form-select id="muteEmailNotificationsSelect" class="mb-2 mr-sm-2 mb-sm-0" v-model="muteNotifications" :options="options"/>

                <div class="py-1"><em class="text-muted">{{ muteUntilTime }}</em></div>
            </b-col>
            <b-col v-else sm="8" md="8" lg="6" xl="4">
                <span style="color: grey;">
                    {{ $t('deviceEdit.noNotificationMethodsPart1') }} <br> {{ $t('deviceEdit.noNotificationMethodsPart2') }}
                </span>
            </b-col>
        </b-row>

        <div v-if="showSensorNotificationsLimits" class="py-3">
            <div class="float-left" style="margin-right: 0.5em;">
                <strong id="sensor-info" style="">
                    {{ $t('deviceEdit.sensorNotificationLimits') }}
                </strong>
            </div>

            <div class="float-left">
                <i id="sensor-limits-info" class="icon-info" tabindex="-1"
                   style="color: #3498db; font-size: 1.05rem; padding-top: 0.24rem; display: inline-block; outline: none; text-decoration: none;"/>

                <b-tooltip target="sensor-limits-info" :title="$t('deviceEdit.sensorNotificationLimitsDesc')" placement="bottom"
                           triggers="hover focus"/>
            </div>
            <br>
            <hr>

            <sensor-notifications-limits/>
        </div>

        <div v-if="userPrivilege > 1">
            <sensors-and-relays-naming/>
        </div>

        <div v-if="userPrivilege === 3" class="pt-3">
            <strong>{{ $t('deviceEdit.deviceSharing') }}</strong>
            <hr>
            <share-device></share-device>
        </div>
    </div>
</template>

<script>
    import SensorsAndRelaysNaming from "./SensorsAndRelaysNaming";
    import SensorNotificationsLimits from "./SensorNotificationsLimits";
    import ShareDevice from "./ShareDevice";
    import {mapGetters} from 'vuex';
    import {debounce} from 'lodash';

    export default {
        name: "GeneralSettings",

        components: {
            SensorsAndRelaysNaming,
            SensorNotificationsLimits,
            ShareDevice
        },

        data() {
            return {
                platform: this.$platform,
                deviceId: this.$route.params.id,
                options: [{value: 0, text: this.$t('deviceEdit.dontMute')}, {value: 1, text: this.$t('deviceEdit.oneHour')},
                    {value: 2, text: this.$t('deviceEdit.sixHours')}, {value: 3, text: this.$t('deviceEdit.twelveHours')},
                    {value: 4, text: this.$t('deviceEdit.oneDay')}, {value: 5, text: this.$t('deviceEdit.muteIndefinitely')}]
            }
        },

        computed: {
            ...mapGetters({
                isAdmin: 'user/isAdmin',
                webNotifications: 'user/webNotifications',
                mobileNotifications: 'user/mobileNotifications',
                emailNotifications: 'user/emailNotifications',
                getUserPrivilege: 'virtualDevice/userPrivilege',
                getDeviceName: 'virtualDevice/deviceName',
                getDeviceDesc: 'virtualDevice/deviceDesc',
                getActiveSensors: 'virtualDevice/activeSensors',
                getMuteNotifications: 'virtualDevice/muteNotifications',
                getMuteUntil: 'virtualDevice/muteUntil',
                getSensorLimit: 'virtualDevice/sensorLimit',
                getUnlinked: 'virtualDevice/unlinked',
                getFirmwareVersion: 'virtualDevice/firmwareVersion'
            }),

            deviceName: {
                get() {
                    return this.getDeviceName(this.deviceId);
                },

                set(value) {
                    this.debounceDeviceNameUpdate(value);
                }
            },

            deviceDesc: {
                get() {
                    return this.getDeviceDesc(this.deviceId);
                },

                set(value) {
                    this.debounceDeviceDescUpdate(value);
                }
            },

            firmwareVersion() {
                let rawVersion = this.getFirmwareVersion({deviceId: this.deviceId});
                if (!rawVersion) return "unknown";
                rawVersion = rawVersion.split('.');

                let type = rawVersion[0];
                let version = rawVersion[1] + '.' + rawVersion[2] + '.' + rawVersion[3];

                return type + ' ' + version
            },

            isUnlinked: function () {
                return this.getUnlinked(this.$route.params.id);
            },

            deviceNameState() {
                return this.deviceName.length >= 4 && this.deviceName.length <= 20
            },

            deviceNameInvalidFeedback() {
                if (this.deviceName.length > 4 && this.deviceName.length <= 20) {
                    return ''
                } else if (this.deviceName.length > 20) {
                    return 'Name must be no more than 20 characters'
                } else if (this.deviceName.length > 0) {
                    return 'Enter at least 4 characters'
                } else {
                    return 'Name must not be empty'
                }
            },

            deviceNameValidFeedback() {
                return '';
            },

            notificationsEnabled() {
                if (this.emailNotifications) return true;
                else if (this.$platform === 'web' && this.webNotifications && (window.Notification && window.Notification.permission === 'granted')) return true;
                else if (this.$platform !== 'web' && this.mobileNotifications) return true;
                else return false;
            },

            showSensorNotificationsLimits() {
                let activeSensors = this.getActiveSensors(this.deviceId);
                return (activeSensors[0] || activeSensors[1] || activeSensors[2] || activeSensors[3]) && this.muteNotifications == 0 && this.notificationsEnabled;
            },

            muteNotifications: {
                get() {
                    return this.getMuteNotifications(this.deviceId);
                },

                set(value) {
                    this.$store.commit('virtualDevice/setMuteNotifications', {
                        deviceId: this.deviceId,
                        muteNotifications: value
                    });

                    this.$axios.post('/update-general-settings', {
                        data: {
                            id: this.deviceId,
                            muteNotifications: this.getMuteNotifications(this.deviceId)
                        }
                    }).then(response => {
                        if (response.data.muteUntil != null) {
                            this.$store.commit('virtualDevice/setMuteUntil', {
                                deviceId: this.deviceId,
                                muteUntil: response.data.muteUntil
                            });
                        }
                    });
                }
            },

            muteUntil: {
                get() {
                    return this.getMuteUntil(this.deviceId);
                },

                set(value) {
                    //
                }
            },

            muteUntilTime: function () {
                if (this.getMuteUntil(this.deviceId) === 0 || this.getMuteUntil(this.deviceId) === -1) {
                    return '';
                } else {
                    let daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
                    let tempDate = new Date();
                    let timezoneOffset = tempDate.getTimezoneOffset();

                    let date = new Date(this.getMuteUntil(this.deviceId) - timezoneOffset * 60 * 1000);
                    let hours = date.getHours();
                    let minutes = "0" + date.getMinutes();
                    let seconds = "0" + date.getSeconds();

                    let offset = new Date().getTimezoneOffset() / 60;
                    hours = hours + offset;

                    if (hours < 0) hours = 24 + hours;
                    else if (hours < 10) hours = "0" + hours;

                    let formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2) + ", " + daysOfWeek[date.getDay() - 1];

                    return 'Mute until ' + formattedTime;
                }
            },

            userPrivilege() {
                return this.getUserPrivilege(this.deviceId);
            }
        },

        created() {
            this.debounceDeviceNameUpdate = debounce(this.updateDeviceName, 500);
            this.debounceDeviceDescUpdate = debounce(this.updateDeviceDesc, 500);
        },

        methods: {
            updateDeviceName(deviceName) {
                if (deviceName.length >= 4 && deviceName.length <= 20) {
                    let data = {
                        id: this.$route.params.id,
                        name: deviceName
                    };

                    this.$axios.post('/update-general-settings', {
                        data: data
                    }).then(response => {
                        this.$store.commit('virtualDevice/setDeviceName', {
                            name: response.data.name,
                            deviceId: this.deviceId
                        });
                    });
                }
            },

            updateDeviceDesc(deviceDesc) {
                if (deviceDesc.length >= 4 && deviceDesc.length <= 20) {
                    let data = {
                        id: this.$route.params.id,
                        desc: deviceDesc
                    };

                    this.$axios.post('/update-general-settings', {
                        data: data
                    }).then(response => {
                        this.$store.commit('virtualDevice/setDeviceDesc', {
                            name: response.data.name,
                            deviceId: this.deviceId
                        });
                    });
                }
            },
        }
    }
</script>

<style scoped>
    .was-validated .form-control:valid, .form-control.is-valid, .was-validated .custom-select:valid, .custom-select.is-valid {
        border-color: #e0e0e0 !important;
    }

    #sensor-info .btn:focus, .btn:active {
        outline: none;
        box-shadow: none;
    }
</style>
