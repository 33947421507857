<template>
    <div>
        <b-row v-if="getActiveSensors(deviceId)[0]" class="pb-3">
            <b-col cols="12" sm="2">
                <label style="padding-top: 0.4em;">S1</label>
            </b-col>

            <b-col cols="6" sm="4" md="4" lg="3" xl="2">
                <b-form-input ref="s1Min" type="number" :min="-58" :max="392" class="input-sm" placeholder="Min" v-model="s1Min"
                              :formatter="sensorLimitFormatter" @input="(value) => {debounceSensor1LimitUpdate(1, value, 'Min')}"
                              @click="$root.$emit('hideBottomNav')"></b-form-input>
            </b-col>

            <b-col cols="6" sm="4" md="4" lg="3" xl="2">
                <b-form-input ref="s1Max" type="number" :min="-58" :max="392" class="input-sm" placeholder="Max" v-model="s1Max"
                              :formatter="sensorLimitFormatter" @input="(value) => {debounceSensor1LimitUpdate(1, value, 'Max')}"
                              @click="$root.$emit('hideBottomNav')"></b-form-input>
            </b-col>
        </b-row>

        <b-row v-if="getActiveSensors(deviceId)[1]" class="pb-3">
            <b-col cols="12" sm="2">
                <label style="padding-top: 0.4em;">S2</label>
            </b-col>

            <b-col cols="6" sm="4" md="4" lg="3" xl="2">
                <b-form-input ref="s2Min" type="number" :min="-58" :max="392" class="input-sm" placeholder="Min" v-model="s2Min"
                              :formatter="sensorLimitFormatter" @input="(value) => {debounceSensor2LimitUpdate(2, value, 'Min')}"
                              @click="$root.$emit('hideBottomNav')"></b-form-input>
            </b-col>

            <b-col cols="6" sm="4" md="4" lg="3" xl="2">
                <b-form-input ref="s2Max" type="number" :min="-58" :max="392" class="input-sm" placeholder="Max" v-model="s2Max"
                              :formatter="sensorLimitFormatter" @input="(value) => {debounceSensor2LimitUpdate(2, value, 'Max')}"
                              @click="$root.$emit('hideBottomNav')"></b-form-input>
            </b-col>
        </b-row>

        <b-row v-if="getActiveSensors(deviceId)[2]" class="pb-3">
            <b-col cols="12" sm="2">
                <label style="padding-top: 0.4em;">S3</label>
            </b-col>

            <b-col cols="6" sm="4" md="4" lg="3" xl="2">
                <b-form-input ref="s3Min" type="number" :min="-58" :max="392" class="input-sm" placeholder="Min" v-model="s3Min"
                              :formatter="sensorLimitFormatter" @input="(value) => {debounceSensor3LimitUpdate(3, value, 'Min')}"
                              @click="$root.$emit('hideBottomNav')"></b-form-input>
            </b-col>

            <b-col cols="6" sm="4" md="4" lg="3" xl="2">
                <b-form-input ref="s3Max" type="number" :min="-58" :max="392" class="input-sm" placeholder="Max" v-model="s3Max"
                              :formatter="sensorLimitFormatter" @input="(value) => {debounceSensor3LimitUpdate(3, value, 'Max')}"
                              @click="$root.$emit('hideBottomNav')"></b-form-input>
            </b-col>
        </b-row>

        <b-row v-if="getActiveSensors(deviceId)[3]">
            <b-col cols="12" sm="2">
                <label style="padding-top: 0.4em;">S4</label>
            </b-col>

            <b-col cols="6" sm="4" md="4" lg="3" xl="2">
                <b-form-input ref="s4Min" type="number" :min="-58" :max="392" class="input-sm" placeholder="Min" v-model="s4Min"
                              :formatter="sensorLimitFormatter" @input="(value) => {debounceSensor4LimitUpdate(4, value, 'Min')}"
                              @click="$root.$emit('hideBottomNav')"></b-form-input>
            </b-col>

            <b-col cols="6" sm="4" md="4" lg="3" xl="2">
                <b-form-input ref="s4Max" type="number" :min="-58" :max="392" class="input-sm" placeholder="Max" v-model="s4Max"
                              :formatter="sensorLimitFormatter" @input="(value) => {debounceSensor4LimitUpdate(4, value, 'Max')}"
                              @click="$root.$emit('hideBottomNav')"></b-form-input>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import {debounce} from 'lodash';

    export default {
        name: "SensorNotificationsLimits",

        components: {},

        data() {
            return {
                deviceId: this.$route.params.id
            }
        },

        computed: {
            ...mapGetters({
                getActiveSensors: 'virtualDevice/activeSensors',
                getSensorLimit: 'virtualDevice/sensorLimit'
            }),

            s1Min: {
                get() {
                    return this.getSensorLimit({deviceId: this.deviceId, sensorId: 1, sensorType: 'Min'});
                },

                set(value) {
                    this.$store.commit('virtualDevice/setSensorLimit', {
                        deviceId: this.deviceId,
                        field: 's1Min',
                        sensorValue: value
                    });
                }
            },

            s1Max: {
                get() {
                    return this.getSensorLimit({deviceId: this.deviceId, sensorId: 1, sensorType: 'Max'});
                },

                set(value) {
                    this.$store.commit('virtualDevice/setSensorLimit', {
                        deviceId: this.deviceId,
                        field: 's1Max',
                        sensorValue: value
                    });
                }
            },

            s2Min: {
                get() {
                    return this.getSensorLimit({deviceId: this.deviceId, sensorId: 2, sensorType: 'Min'});
                },

                set(value) {
                    this.$store.commit('virtualDevice/setSensorLimit', {
                        deviceId: this.deviceId,
                        field: 's2Min',
                        sensorValue: value
                    });
                }
            },

            s2Max: {
                get() {
                    return this.getSensorLimit({deviceId: this.deviceId, sensorId: 2, sensorType: 'Max'});
                },

                set(value) {
                    this.$store.commit('virtualDevice/setSensorLimit', {
                        deviceId: this.deviceId,
                        field: 's2Max',
                        sensorValue: value
                    });
                }
            },

            s3Min: {
                get() {
                    return this.getSensorLimit({deviceId: this.deviceId, sensorId: 3, sensorType: 'Min'});
                },

                set(value) {
                    this.$store.commit('virtualDevice/setSensorLimit', {
                        deviceId: this.deviceId,
                        field: 's3Min',
                        sensorValue: value
                    });
                }
            },

            s3Max: {
                get() {
                    return this.getSensorLimit({deviceId: this.deviceId, sensorId: 3, sensorType: 'Max'});
                },

                set(value) {
                    this.$store.commit('virtualDevice/setSensorLimit', {
                        deviceId: this.deviceId,
                        field: 's3Max',
                        sensorValue: value
                    });
                }
            },

            s4Min: {
                get() {
                    return this.getSensorLimit({deviceId: this.deviceId, sensorId: 4, sensorType: 'Min'});
                },

                set(value) {
                    this.$store.commit('virtualDevice/setSensorLimit', {
                        deviceId: this.deviceId,
                        field: 's4Min',
                        sensorValue: value
                    });
                }
            },

            s4Max: {
                get() {
                    return this.getSensorLimit({deviceId: this.deviceId, sensorId: 4, sensorType: 'Max'});
                },

                set(value) {
                    this.$store.commit('virtualDevice/setSensorLimit', {
                        deviceId: this.deviceId,
                        field: 's4Max',
                        sensorValue: value
                    });
                }
            }
        },

        created() {
            this.debounceSensor1LimitUpdate = debounce(this.updateSensorLimit, 800);
            this.debounceSensor2LimitUpdate = debounce(this.updateSensorLimit, 800);
            this.debounceSensor3LimitUpdate = debounce(this.updateSensorLimit, 800);
            this.debounceSensor4LimitUpdate = debounce(this.updateSensorLimit, 800);
        },

        methods: {
            updateSensorLimit(sensorId, value, type) {
                let data = {
                    id: this.deviceId,
                };
                let sensorField = 's' + sensorId + type;
                data[sensorField] = parseInt(value);

                this.$axios.post('/update-general-settings', {
                    data: data
                }).then(response => {
                    let minField = 's' + sensorId + 'Min';
                    let maxField = 's' + sensorId + 'Max';

                    if (response.data[minField] === null || response.data[maxField] === null) {
                        this.$refs[sensorField].$emit('update', '');

                        if (response.data[minField] !== undefined) {
                            this.$store.commit('virtualDevice/setSensorLimit', {
                                deviceId: this.deviceId,
                                field: minField,
                                sensorValue: response.data[minField]
                            });
                        }

                        if (response.data[maxField] !== undefined) {
                            this.$store.commit('virtualDevice/setSensorLimit', {
                                deviceId: this.deviceId,
                                field: maxField,
                                sensorValue: response.data[maxField]
                            });
                        }
                    }
                });
            },

            sensorLimitFormatter(value, event) {
                if (value === '-' || event.data === '-') {
                    //Condition needed so we can write minuses
                } else if (parseInt(value) < -50) {
                    document.getElementById(event.target.id).value = -50;

                    return "-50";
                } else if (parseInt(value) > 200) {
                    document.getElementById(event.target.id).value = 200;

                    return "200";
                } else {
                    //We parse it beforehand so we can remove leading zeroes
                    let val = value !== '' ? parseInt(value) : value;
                    document.getElementById(event.target.id).value = val;

                    return val.toString();
                }
            }
        }
    }
</script>

<style scoped>

</style>