<template>
    <div>
        <b-row v-for="(user, index) in sharedWith" class="">
            <b-col cols="12" sm="9" md="5" lg="4" class="py-sm-2">
                <b-form-input readonly :key="index" type="text" :placeholder="$t('deviceEdit.enterEmail')" :value="user.email"/>
            </b-col>
            <b-col cols="6" sm="6" md="3" lg="3" xl="2" class="py-2">
                <b-form-select :value="user.privilege_level" :options="options" @change="(value) => {updatePrivilege(value, user.email)}"
                               class="mb-3">
                </b-form-select>
            </b-col>
            <b-col cols="6" sm="6" md="4" lg="5" class="py-2">
                <b-button variant="warning" @click="unshareDevice(user.email)">{{ $t('deviceEdit.unshare') }}</b-button>
            </b-col>
        </b-row>

        <b-row>
            <b-col cols="12" sm="9" md="5" lg="4" class="py-sm-2">
                <b-form-input v-model="emailToShareWith" type="text" :placeholder="$t('deviceEdit.enterEmail')"
                              @click="$root.$emit('hideBottomNav')"/>
            </b-col>
            <b-col cols="6" sm="6" md="3" xl="2" lg="3" class="py-2">
                <b-form-select v-model="selected" :options="options" class="mb-3"/>
            </b-col>
            <b-col cols="6" sm="6" md="4" lg="5" class="py-2">
                <b-button variant="primary" @click="shareDevice">{{ $t('deviceEdit.share') }}</b-button>
            </b-col>
        </b-row>

        <InfoModal/>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import InfoModal from "@/components/general-purpose-modals/InfoModal";

    export default {
        name: "ShareDevice",
        components: {
            InfoModal
        },

        data() {
            return {
                emailToShareWith: "",
                selected: 1,
                options: [
                    {value: '1', text: this.$t('deviceEdit.viewer')},
                    {value: '2', text: this.$t('deviceEdit.maintainer')},
                ]
            }
        },

        computed: {
            ...mapGetters({
                getSharedWith: 'virtualDevice/sharedWith'
            }),

            sharedWith: {
                get() {
                    return this.getSharedWith(this.$route.params.id);
                },

                set(value) {
                    //
                }
            }
        },

        methods: {
            shareDevice() {
                let data = {
                    id: this.$route.params.id,
                    emailToShareWith: this.emailToShareWith,
                    privilegeLevel: this.selected
                };

                this.$axios.post('/share-device', {
                    data: data
                }).then(response => {
                    if (response.data.type === 'InvalidEmail') {
                        this.$root.$emit('showInfo', this.$t('deviceEdit.accountNotFound'));
                        this.$root.$emit('bv::show::modal', 'info-modal');
                    } else if (response.data.type === 'AlreadyExists') {
                        this.$root.$emit('showInfo', this.$t('deviceEdit.alreadyShared'));
                        this.$root.$emit('bv::show::modal', 'info-modal');
                    } else if (response.data.type === 'MaxShareNumberReached') {
                        this.$root.$emit('showInfo', this.$t('deviceEdit.maximumSharingNumber'));
                        this.$root.$emit('bv::show::modal', 'info-modal');
                    } else {
                        this.$store.commit('virtualDevice/addToSharedWith', {
                            deviceId: this.$route.params.id,
                            newUser: {
                                "email": this.emailToShareWith,
                                "privilege_level": this.selected
                            }
                        });
                        this.emailToShareWith = "";
                        this.selected = 1;
                    }
                });
            },

            unshareDevice(email) {
                let data = {
                    id: this.$route.params.id,
                    emailToUnshareWith: email
                };

                this.$axios.post('/unshare-device', {
                    data: data
                }).then(() => {
                    this.$store.commit('virtualDevice/removeFromSharedWith', {
                        deviceId: this.$route.params.id,
                        email: email
                    });
                });
            },

            updatePrivilege(value, email) {
                let data = {
                    id: this.$route.params.id,
                    emailToUpdate: email,
                    newPrivilegeLevel: value
                };

                this.$axios.post('/update-privilege', {
                    data: data
                }).then(() => {
                    this.$root.$emit('showInfo', this.$t('deviceEdit.privilegeUpdateSuccess'));
                    this.$root.$emit('bv::show::modal', 'info-modal');
                }).catch(() => {
                    this.$root.$emit('showInfo', this.$t('deviceEdit.privilegeUpdateFail'));
                    this.$root.$emit('bv::show::modal', 'info-modal');
                });
            }
        }
    }
</script>

<style scoped>

</style>